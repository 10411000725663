<template>
	<v-card>
		<v-toolbar color="primary">
			<v-toolbar-title class="white--text">Cambio de estado</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn icon color="white" @click="$emit('close')">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-toolbar>

		<div class="pa-4">
			<span class="text-h6">Estudio: {{ estudio }}</span>

			<v-list dense>
				<v-list-item
					@click="nuevoEstado = estado"
					v-for="estado in estados"
					:key="estado.idEstado"
				>
					<status-chip :value="estado.estado"></status-chip>
				</v-list-item>
			</v-list>
			<template v-if="nuevoEstado">
				<v-divider></v-divider>
				<div class="d-flex align-center">
					<v-card-title> Cambiar estado a : </v-card-title>
					<status-chip :key="nuevoEstado.idEstado" :value="nuevoEstado.estado"></status-chip>
					<v-spacer></v-spacer>
					<v-btn @click.stop="cambiarEstado" color="primary" outlined
						>Aceptar</v-btn
					>
				</div>
			</template>
		</div>
		    <ConfirmDialog ref="confirm" />
	</v-card>
</template>

<script>
export default {
	components: {
		StatusChip: () => import("@/components/StatusChip.vue"),
		    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
	},
	props: {
		estudio: String | Number,
		interno: Boolean
	},
	data() {
		return {
			nuevoEstado: null,
			estados: [],
		};
	},
	async mounted() {
		try {
			var { data: estados } = await axios({
				method: "get",
				url: `${process.env.VUE_APP_API_URL}/opciones/estados.php`,
				params: {
					interno: this.interno ? 1 : 0
				}
			});
			this.estados = estados;
		} catch (e) {
			console.log(e);
			this.$root.$emit("snack", "Ha ocurrido un error");
		}
	},
	methods: {
		async cambiarEstado() {
      if (!await this.$refs.confirm.open('Cambiar estados', '¿Estás seguro de que quieres cambiar estos estudios a '+this.nuevoEstado.estado+'?')) return;

			await axios({
				method: "POST",
				url: `${process.env.VUE_APP_API_URL}/cambiarEstadoEstudios.php`,
				data: {
					idEstudio: this.estudio,
					estado: this.nuevoEstado.idEstado,
				},
			})
				.then((res) => {
					this.$root.$emit("snack", "Se ha cambiado el estado con éxito");
					this.cambioEstado = false;
					this.$emit('close');
					this.$emit('reload');
				})
				.catch((err) => {
					console.error(err);
					this.$root.$emit("snack", "No se ha podido cambiar el estado");
				});
		},
	},
};
</script>

<style>
</style>